import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native-web";
import React from "react";
import { Colors, Fonts, Images } from "../../../config";

const SelectedItemTopBarComponent = (props) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          null;
        }}
      >
        <Image source={Images.iconClose} style={styles.icon} />
      </TouchableOpacity>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{props.selected} MESSAGES SELECTED</Text>
      </View>

      <View
        style={{
          position: "absolute",
          right: 14,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            null;
          }}
        >
          <Image
            source={Images.iconDelete}
            style={{ width: 22, height: 22, resizeMode: "contain" }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SelectedItemTopBarComponent;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Colors.black,
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  titleContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: "contain",
  },
});
