import React, { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";
// import LinearGradient frm "react-native-linear-gradient";

const BarRatingView = (props) => {
  const { t } = useTranslation();
  const { rating, isMobileVersion } = props;
  const maxRating = props.maxRating || 5;
  const level = Math.min(Math.floor(rating), maxRating);
  const maxHeight = props.maxHeight || (isMobileVersion ? 12 : 20);
  const renderBar = (height, isActive, key) => {
    return (
      <View
        key={key}
        style={[
          styles.bar,
          isMobileVersion ? { width: 1.8 } : {},
          { height: height },
          props.barStyle,
        ]}>
        {isActive && (
          <div
            className="bg-gradient-to-b from-gradient3 via-gradient2 to-gradient1"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </View>
    );
  };
  const renderBars = () => {
    const bars = [];
    for (let i = 1; i <= maxRating; i++) {
      const height = (maxHeight / maxRating) * i;
      const isActive = i <= level;
      bars.push(renderBar(height, isActive, i + "bar"));
    }
    return bars;
  };
  return <View style={[styles.container, props.style]}>{renderBars()}</View>;
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "flex-end" },
  bar: {
    borderRadius: 2,
    marginLeft: 1.07,
    width: 3.21,
    borderRadius: 3.1,
    backgroundColor: Colors.barTansparentBg,
    overflow: "hidden",
  },
});

export default BarRatingView;
