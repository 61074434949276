import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts, Constants } from "../../config";
import { CImage, GradientText } from ".";
import { useRouter } from "next/router";

const PostActionBottomView = (props) => {
  const { favorite, comment, share, textStyle, tintColor } = props;
  const isFavorite = props.isFavorite == true;
  const iconTintColor = tintColor || Colors.grayBorder;
  const onMenuPress = (type) => {
    if (props.onMenuPress) {
      props.onMenuPress(type);
    }
  };
  const navigation = useRouter();
  const viewListUser = (mode, props) => {
    navigation.navigate("ListLikers", {
      mode: mode,
      id: props.id,
    });
  };
  return (
    <View style={styles.container}>
      <View style={styles.bottomBarContainer}>
        <View style={styles.itemContainer}>
          <TouchableOpacity
            style={styles.iconContainer}
            onPress={() => {
              onMenuPress(Constants.actionType.ACTION_FAVORITE);
            }}
          >
            <CImage
              source={
                isFavorite
                  ? Images.iconFavoriteColored
                  : Images.iconActionFavorite
              }
              style={styles.icon}
              height={20}
              width={20}
              layout="fixed"
              tintColor={isFavorite ? null : iconTintColor}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <View style={styles.itemContainer}>
          <TouchableOpacity
            style={styles.iconContainer}
            onPress={() => {
              onMenuPress(Constants.actionType.ACTION_COMMENT);
            }}
          >
            <CImage
              source={Images.iconActionComment}
              style={styles.icon}
              tintColor={iconTintColor}
              height={20}
              width={20}
              layout="fixed"
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <View style={styles.itemContainer}>
          <TouchableOpacity
            style={styles.iconContainer}
            onPress={() => {
              onMenuPress(Constants.actionType.ACTION_SHARE);
            }}
          >
            <CImage
              source={Images.iconActionShare}
              style={styles.icon}
              tintColor={iconTintColor}
              height={20}
              width={20}
              layout="fixed"
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.bottomStatusContainer}>
        <View style={styles.itemContainer}>
          {isFavorite ? (
            <TouchableOpacity
              style={{
                width: 28,
                height: 28,
                justifyContent: "center",
                alignItems: "center",
                margin: -14,
              }}
              onPress={() => viewListUser("LIKES", props)}
            >
              <GradientText style={[styles.statusText, textStyle]}>
                {favorite}
              </GradientText>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={{
                width: 28,
                height: 28,
                justifyContent: "center",
                alignItems: "center",
                margin: -14,
              }}
              onPress={() => viewListUser("LIKES", props)}
            >
              <Text style={[styles.statusText, textStyle]}>{favorite}</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.itemContainer}>
          <TouchableOpacity>
            <Text style={[styles.statusText, textStyle]}>{comment ?? "0"}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.itemContainer}>
          <TouchableOpacity>
            <Text style={[styles.statusText, textStyle]}>{share ?? "0"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.grayBackground,
    alignSelf: "stretch",
  },
  iconContainer: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  bottomBarContainer: {
    height: 48,
    alignSelf: "stretch",
    flexDirection: "row",
    borderBottomColor: Colors.tabBottomColor,
    borderBottomWidth: 1,
  },
  bottomStatusContainer: {
    height: 31,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  itemContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 16,
    height: 16,
  },
  statusText: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 18,
    color: Colors.grayBorder,
  },
});

export default PostActionBottomView;
