import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../config";
// import Popover from "react-native-popover-view";
const CPopover = (props) => {
  return (
    // <Popover popoverStyle={styles.popoverStyle} {...props}>
    //   {props.children}
    // </Popover>
    null
  );
};

const styles = StyleSheet.create({
  popoverStyle: {
    backgroundColor: Colors.black,
    borderRadius: 12,
    overflow: "hidden",
  },
});

export default CPopover;
