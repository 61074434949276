import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Colors, Fonts } from "../../config";
import CImage from "./CImage";
const StateButton = (props) => {
  return (
    <TouchableOpacity
      style={[
        styles.buttonContainer,
        props.style,
        props.isActive && { borderWidth: 0 },
      ]}
      onPress={props.onPress}
    >
      {/* <Image source={props.icon} style={styles.icon} resizeMode={"contain"} />       */}
      {/* <CImage source={props.icon} style={styles.icon}  width={15} height={15} /> */}
      <Text style={styles.buttonText}>{props.title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  buttonContainer: {
    borderRadius: 12,
    height: 24,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    alignItems: "center",
    justifyContent: "center",
    width: 104,
    flexDirection: "row",
    overflow: "hidden",
  },
  buttonText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    color: "white",
  },
});

export default StateButton;
