import React, { useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Animated,
  ScrollView,
  Dimensions,
} from "react-native-web";
import { Colors, Constants, Fonts, Values } from "../../../config";
import CImage from "../CImage";
var prevAnimVal = 0;

const CCarouselView = (props) => {
  const { height } = props;
  const carouselRef = useRef(null);

  useEffect(() => {
    scrollToItem(props.selectedItem);
  }, [props.selectedItem]);
  var carouselWidth = Values.deviceWidth;
  var BAR_SPACE = 16;
  var itemWidth = 10;
  if (props.carouselWidth) {
    carouselWidth = props.carouselWidth;
  }
  if (props.barSpace) {
    BAR_SPACE = props.barSpace;
  }
  if (props.indicatorItemWith) {
    itemWidth = props.indicatorItemWith;
  }
  var animVal = new Animated.Value(prevAnimVal);
  const items = props.items;

  const scrollToItem = (item) => {
    if (!item) return;
    let indexToScroll = null;
    items.find((x, index) => {
      if (x.id == item.id) {
        indexToScroll = index;
        return true;
      }
      return false;
    });

    if (indexToScroll !== null && carouselRef) {
      const xScroll = indexToScroll * Values.deviceWidth;
      carouselRef.current.scrollTo({ x: xScroll, animated: false });
      prevAnimVal = xScroll;
    }
  };

  const renderItem = (item, i) => {
    if (props.renderItem) {
      return props.renderItem(item, i);
    }

    return (
      <View
        key={`item${i}`}
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "stretch",
          flex: 1,
        }}
      >
        <View style={styles.sliderItemContainer}>
          <CImage
            imgUrl={item.url}
            style={styles.imageContainer}
            height={450}
            width={Constants.PROFILE_PAGE_WIDTH}
            layout="fixed"
            objectFit={"fill"}
          />
        </View>
      </View>
    );
  };

  const renderItems = (items) => {
    if (items == undefined) {
      return [];
    }
    let itemViewArray = [];
    items.forEach((item, i) => {
      const itemView = renderItem(item, i);
      itemViewArray.push(itemView);
    });
    return itemViewArray;
  };

  const renderIndicatorItem = (item, i) => {
    const scrollBarVal = animVal.interpolate({
      inputRange: [carouselWidth * (i - 1), carouselWidth * (i + 1)],
      outputRange: [-itemWidth, itemWidth],
      extrapolate: "clamp",
    });
    return (
      <View
        key={`bar${i}`}
        style={[
          styles.track,
          props.dotStyle,
          {
            borderRadius: itemWidth,
            height: itemWidth,
            width: itemWidth,
            marginLeft: i === 0 ? 0 : BAR_SPACE,
          },
        ]}
      >
        <Animated.View
          style={[
            styles.bar,
            props.dotSelectStyle,
            {
              borderRadius: itemWidth,
              height: itemWidth,
              width: itemWidth,
              transform: [{ translateX: scrollBarVal }],
            },
          ]}
        />
      </View>
    );
  };
  const renderIndicator = (items) => {
    if (items == undefined) {
      return [];
    }
    let indicatorArray = [];
    items.forEach((item, i) => {
      const indicatorItemView = renderIndicatorItem(item, i);
      indicatorArray.push(indicatorItemView);
    });
    return indicatorArray;
  };
  const onScrollEnd = (x) => {
    const { items } = props;
    const page = Math.floor(x / Values.deviceWidth);
    if (props.onChange && page < items.length) {
      props.onChange(items[page]);
    }
  };

  return (
    <View style={[styles.container, props.style]}>
      <ScrollView
        horizontal
        ref={carouselRef}
        showsHorizontalScrollIndicator={false}
        snapToInterval={carouselWidth}
        decelerationRate={0}
        onMomentumScrollEnd={(e) => {
          const x = e.nativeEvent.contentOffset.x;
          onScrollEnd(x);
          prevAnimVal = x;
        }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: animVal } } }],
          { useNativeDriver: false }
        )}
      >
        {renderItems(items)}
      </ScrollView>
      {!props.hideDots && items.length < 12 && (
        <View style={[styles.barContainer, props.barContainerStyle]}>
          {renderIndicator(items)}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    alignSelf: "stretch",
  },
  sliderItemContainer: {
    flex: 1,
    flexDirection: "column",
    width: Values.deviceWidth,
    height: "100%",
    justifyContent: "center",
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    flexDirection: "column",
    backgroundColor: Colors.black,
    justifyContent: "center",
    paddingVertical: 30,
    borderTopColor: Colors.lightGray,
  },
  buttonNext: {
    height: 50,
    borderRadius: 10,
    justifyContent: "center",
    marginHorizontal: 35,
    backgroundColor: Colors.orange,
  },
  buttonTextNext: {
    fontSize: 14,
    fontFamily: Fonts.primarySemiBold,
    textAlign: "center",
    color: Colors.white,
  },
  barContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 24,
  },
  track: {
    backgroundColor: Colors.darkWhite,
    overflow: "hidden",
  },
  bar: {
    backgroundColor: Colors.white,
    position: "absolute",
  },
  centeredTextRegularLarge: {
    fontSize: Values.fontSize.medium,
    fontFamily: Fonts.primaryMedium,
    textAlign: "center",
    color: Colors.darkGray,
    alignSelf: "stretch",
    paddingVertical: 8,
    marginHorizontal: 32,
    lineHeight: 30,
  },
  text: {
    color: Colors.white,
    fontSize: 26,
    lineHeight: 27,
    textAlign: "center",
    fontFamily: Fonts.primaryRegular,
    fontWeight: "bold",
  },
  title: {
    color: Colors.orange,
    fontSize: 22,
    fontFamily: Fonts.primaryBold,
  },
  sliderItemTextContainer: {
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "center",
    marginBottom: 60,
  },
});

export default CCarouselView;
