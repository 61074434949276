import React, { Component } from "react";
import { StatusBar } from "react-native-web";
import { Colors } from "../../../config";

class Statusbar extends Component {
  render() {
    return (
      <StatusBar
        backgroundColor={Colors.primaryColor}
        barStyle="light-content"
      />
    );
  }
}

export default Statusbar;
