import React, { useEffect, useCallback, useState, useRef } from "react";
import { Colors, Constants, Fonts, Images } from "../../config";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
} from "react-native-web";
import { useTranslation } from "react-i18next";
import CImage from "./CImage";

const TopMenuItem = (props) => {
  const { t } = useTranslation();
  const { disabled, disabledIcon, icon, title, isMobileVersion } = props;
  return (
    <TouchableOpacity
      style={[styles.container, props.style]}
      onPress={() => {
        if (props.onPressItem) {
          props.onPressItem();
        }
      }}
      disabled={disabled}>
      {disabledIcon && icon && (
        <CImage
          width={12}
          height={12}
          source={disabled ? disabledIcon : icon}
          alt="icon"
          layout="fixed"
        />
      )}
      {title && (
        <Text
          style={[
            styles.text,
            disabled && styles.disabledText,
            isMobileVersion ? { fontSize: 10, lineHeight: 18 } : {},
          ]}>
          {title}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  text: {
    lineHeight: 18,
    fontSize: 16,
    color: Colors.white60,
    fontFamily: Fonts.primaryRegular,
    marginLeft: 5,
  },
  disabledText: {
    color: Colors.white20,
  },
});

export default TopMenuItem;
