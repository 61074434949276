//api base urls

var developmentRelease = false;
var productionDevelopment = false;

var development = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  mapAPIKey: "AIzaSyDzZpj4cB8ijdRVffPk0wV6BXdTlTrkiy0",
};

var production = {};

var staging = {};

export default development; //(!productionDevelopment && (__DEV__ || developmentRelease)) ? staging : production
