import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import CImage from "./CImage";
import LinearGradientView from "./LinearGradientView";

const BubbleItem = (props) => {
  const { item } = props;
  const { icon } = item;
  return (
    <TouchableOpacity
      style={styles.bubbleContainer}
      onPress={() => {
        if (props.onPressItem) {
          props.onPressItem(item);
        }
      }}
    >
      <LinearGradientView
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <CImage 
          height={22}
          width={22}
          layout="fixed"
         source={icon} style={styles.icon} />
    </TouchableOpacity>
  );
};

const BubbleMenu = (props) => {
  const { items } = props;
  if (!items) return null;
  const renderBubbles = (bubbleItems) => {
    return bubbleItems.map((item, index) => {
      return (
        <BubbleItem
          key={"bubble" + index}
          item={item}
          onPressItem={props.onPressItem}
        />
      );
    });
  };
  return (
    <View style={[styles.container, props.style]}>{renderBubbles(items)}</View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    right: 0,
    flexDirection: "column",
  },
  icon: {
    width: 20,
    height: 20,
  },
  text: {
    color: Colors.whiteText,
    fontFamily: Fonts.primaryRegular,
  },
  bubbleContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginBottom: 12,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
});

export default BubbleMenu;
