import React from "react";
import { View } from "react-native-web";
// import ProgressCircle from "react-native-progress-circle";
import { Colors } from "../../config";

const CPercentageCircle = (props) => {
  return (
    //   <ProgressCircle
    //     color={Colors.white}
    //     shadowColor={Colors.spliterLine}
    //     {...props}
    //   />
    null
  );
};

export default CPercentageCircle;
