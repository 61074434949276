import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native-web";
import React from "react";
import { Colors, Fonts, Images } from "../../../config";

const ChatComponent = (props) => {
  return (
    <TouchableOpacity style={styles.container}>
      <View style={styles.pictureContainer}>
        <Image source={Images.iconPhoto} />
      </View>
      <View style={styles.chatContainer}>
        <Text style={styles.name}>{props.name}</Text>
        <Text style={styles.chat} numberOfLines={1}>
          {props.chat}
        </Text>
      </View>
      <View style={styles.timeContainer}>
        <View style={{ flex: 1, justifyContent: "flex-end" }}>
          <Text style={styles.time}>14:37</Text>
        </View>
        <View style={{ flex: 1 }}>
          {props.count ? (
            <View style={styles.circleContainer}>
              <Text style={styles.circleText}>{props.count}</Text>
            </View>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ChatComponent;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 63,
    backgroundColor: Colors.darkGrayCard,
    borderRadius: 16,
    flexDirection: "row",
    marginBottom: 8,
  },
  pictureContainer: {
    flex: 0.25,
    justifyContent: "center",
    alignItems: "center",
  },
  chatContainer: {
    flex: 1,
    justifyContent: "center",
  },
  timeContainer: {
    flex: 0.2,
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    color: Colors.white,
    marginBottom: 4,
  },
  chat: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    color: Colors.grayText,
  },
  time: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    color: Colors.grayText,
    marginBottom: 4,
  },
  circleContainer: {
    width: 16,
    height: 16,
    borderRadius: 30,
    backgroundColor: Colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  circleText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    color: Colors.black,
  },
});
