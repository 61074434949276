import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Image,
  TouchableOpacity,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../config";
import { useRouter } from "next/router";
import { formatNumber } from "../../../helpers";
import { getClass } from "../../../helpers/formatHelpers";

const RoundTabItem = (props) => {
  const navigation = useRouter();
  const { isMobileVersion, isHeading } = props;
  let titleText = props.item.title;

  let countText = formatNumber(props.item.count);

  const disabled = props.item.disabled;
  const onSelectTab = (item, index) => {
    if (props.onSelectTab) {
      props.onSelectTab(item, index);
    }
  };
  return (
    <TouchableOpacity
      style={[
        styles.tabItemContainer,
        props.isPicked && styles.selectedTabContainer,
        isMobileVersion ? { height: 32 } : {},
      ]}
      disabled={disabled}
      onPress={() => {
        onSelectTab(props.item, props.index);
      }}>
      <View
        style={[
          styles.tabItemContainer,
          isMobileVersion ? { height: 32 } : {},
        ]}>
        <Text
          style={[
            props.isPicked ? styles.selectedTabItemText : styles.tabItemText,
            isMobileVersion ? { fontSize: 12 } : {},
            props.isUppercase ? { textTransform: "uppercase" } : {},
          ]}>
          {isHeading ? <h2>{titleText}</h2> : titleText}
        </Text>
        {!props?.item?.withoutCount && (
          <Text
            style={[
              props.isPicked
                ? styles.selectedTabItemCountText
                : styles.tabItemText,
              isMobileVersion ? { fontSize: 12 } : {},
            ]}>
            {countText}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  tabItemContainer: {
    flex: 1,
    height: 50,
    borderRadius: 25,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedTabContainer: {
    backgroundColor: getClass("bg-gray1"),
  },
  selectedTabItemText: {
    fontSize: 16,
    lineHeight: 18,
    color: "white",
    fontFamily: Fonts.primaryBold,
  },
  selectedTabItemCountText: {
    fontSize: 16,
    lineHeight: 18,
    color: Colors.grayText2,
    fontFamily: Fonts.primaryBold,
    paddingLeft: 6,
  },
  tabItemText: {
    fontSize: 16,
    lineHeight: 18,
    color: "#535353",
    fontFamily: Fonts.primaryBold,
    paddingLeft: 6,
  },
});

export default RoundTabItem;
