import Moment from "moment";
import { Colors, Constants, Fonts } from "../config";
import { calculateMilisecondDiff } from "./dateHelpers";

export function formatDate(dateString, formatString = "YYYY-MM-DD", isUtc) {
  if (!dateString) {
    return "-";
  }
  const momentObj = Moment(dateString);
  let momentString = momentObj.format(formatString);
  if (isUtc) {
    momentString = momentObj.utc().format(formatString);
  }
  if (momentString == "Invalid date") return "-";
  return momentString;
}
export function getDateString(date) {
  if (!date) return null;
  return date.substring(0, 10);
}

export function getClass(classType, isTailwind, conditionBoolean) {
  if (classType == "header" || classType == "footer") {
    let result = isTailwind ? "bg-realblack" : Colors.black;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-zooGreen" : Colors.zooGreen;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-prefabGray" : Colors.prefabGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-parkfulBlue" : Colors.parkfulBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-realblack" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-castleGray" : Colors.castleGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-resortBlue" : Colors.resortBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-realblack" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-rooftopBlue" : Colors.rooftopBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-spaGray" : Colors.spaGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-beachGray" : Colors.beachGray;
    }
    return result;
  } else if (classType == "scrollbar") {
    let result = isTailwind ? "bg-spliterLine" : Colors.spliterLine;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    }
    return result;
  } else if (classType == "background") {
    let result = isTailwind ? "bg-black" : Colors.black;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "background5") {
    let result = isTailwind ? "bg-black5" : Colors.black5;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black5" : Colors.black5;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black5" : Colors.black5;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "svg-white") {
    let result = "white";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "black";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "black";
    }
    return result;
  } else if (classType == "bg-card-continent") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-card-continent-label") {
    let result = isTailwind ? "bg-grayText" : Colors.grayText;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayText" : Colors.grayText;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayText" : Colors.grayText;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    }
    return result;
  } else if (classType == "bg-gray1") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    }
    return result;
  } else if (classType == "bg-gray1-active-category") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-prefabBlack" : Colors.prefabBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-castleBlack" : Colors.castleBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-spaBlue" : Colors.spaBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-beachPink" : Colors.beachPink;
    }
    return result;
  } else if (classType == "bg-gray1LightGray") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-gray2") {
    let result = isTailwind ? "bg-darkGrayCard" : Colors.darkGrayCard;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-darkGrayCard" : Colors.darkGrayCard;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-darkGrayCard" : Colors.darkGrayCard;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-grey2" : Colors.grey2;
    }
    return result;
  } else if (classType == "bg-tab-gray1") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "border-tab-gray1") {
    let result = "transparent";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "transparent";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "transparent";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = Colors.lightGray;
    }
    return result;
  } else if (classType == "border-grayBackground") {
    let result = "border-grayBackground";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "border-grayBackground";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "border-grayBackground";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "border-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "border-white";
    }
    return result;
  } else if (classType == "border-gray") {
    let result = "border-gray";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "border-gray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "border-gray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "border-tabBottomColor";
    }
    return result;
  } else if (classType == "border-tab-gray1-width") {
    let result = 0;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = 0;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = 0;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = 1;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = 1;
    }
    return result;
  } else if (classType == "hover:bg-gray1") {
    let result = "hover:bg-gray1";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "hover:bg-gray1";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "hover:bg-gray1";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "hover:bg-lightGray";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "hover:bg-lightGray";
    }
    return result;
  } else if (classType == "hover:bg-grayBackground") {
    let result = "hover:bg-grayBackground";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "hover:bg-grayBackground";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "hover:bg-grayBackground";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "hover:bg-grey2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "hover:bg-grey2";
    }
    return result;
  } else if (classType == "text-white") {
    let result = isTailwind ? "text-white" : Colors.white;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "text-white50") {
    let result = isTailwind ? "text-white50" : Colors.white50;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-black4" : Colors.black4;
    }
    return result;
  } else if (classType == "text-white80") {
    let result = isTailwind ? "text-white80" : Colors.white80;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-black4" : Colors.black4;
    }
    return result;
  } else if (classType == "text-pink") {
    let result = isTailwind ? "text-pink" : Colors.pink;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-zooBlue" : Colors.zooBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-prefabBlack" : Colors.prefabBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-parkfulPink" : Colors.parkfulPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-casinoRed" : Colors.casinoRed;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-castleBlack" : Colors.castleBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-resortPink" : Colors.resortPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-aparthotelPink" : Colors.aparthotelPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-rooftopPink" : Colors.rooftopPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spaBlue" : Colors.spaBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-beachPink" : Colors.beachPink;
    }
    return result;
  } else if (classType == "text-white70") {
    let result = isTailwind ? "text-white70" : Colors.white70;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-white70" : Colors.white70;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-white70" : Colors.white70;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "btn-text-black") {
    let result = isTailwind ? "text-black" : Colors.black;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "text-gray") {
    let result = isTailwind ? "text-grayText" : Colors.grayText;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-grayText" : Colors.grayText;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-grayText" : Colors.grayText;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "text-gray2") {
    let result = isTailwind ? "text-grayText2" : Colors.grayText2;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-grayText2" : Colors.grayText2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-grayText2" : Colors.grayText2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "text-gray2-white") {
    let result = isTailwind ? "text-grayText2" : Colors.grayText2;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-grayText2" : Colors.grayText2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-grayText2" : Colors.grayText2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-white" : Colors.white;
    }
    return result;
  } else if (classType == "text-gray2-dark") {
    let result = isTailwind ? "text-grayText2" : Colors.grayText2;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-grayText2" : Colors.grayText2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-grayText2" : Colors.grayText2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "text-darkGray") {
    let result = isTailwind ? "text-darkGray" : Colors.darkGray;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-darkGray" : Colors.darkGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-darkGray" : Colors.darkGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "text-black3") {
    let result = isTailwind ? "text-black3" : Colors.black3;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-black3" : Colors.black3;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-black3" : Colors.black3;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "weight-tab-bold") {
    let result = Fonts.primaryBold;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = Fonts.primaryBold;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = Fonts.primaryBold;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = conditionBoolean ? Fonts.primaryBold : Fonts.primaryRegular;
    }
    return result;
  } else if (classType == "border-black") {
    let result = isTailwind ? "border-spliterLine" : Colors.spliterLine;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "border-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "border-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-icon-tag") {
    let result = isTailwind ? "bg-white10" : Colors.white10;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-white10" : Colors.white10;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-white10" : Colors.white10;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-icon-gray1") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-input") {
    let result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground" : Colors.grayBackground;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "btn-gradient") {
    let result = "bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "bg-zooBlue";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "bg-prefabBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "bg-parkfulPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "bg-casinoRed";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "bg-castleBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "bg-resortPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "bg-aparthotelPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "bg-rooftopPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl") {
      result = "bg-cocktaylPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "bg-spaBlue";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "bg-beachPink";
    }
    return result;
  } else if (classType == "btn-gradient2") {
    let result = "bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "bg-zooBlue";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "bg-prefabBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "bg-parkfulPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "bg-casinoRed";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "bg-castleBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "bg-resortPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "bg-aparthotelPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "bg-rooftopPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl") {
      result = "bg-cocktaylPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "bg-prefabBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "bg-beachPink";
    }
    return result;
  } else if (classType == "bg-grayBackground3") {
    let result = isTailwind ? "bg-grayBackground3" : Colors.grayBackground3;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white70" : Colors.white70;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-grayBackground3" : Colors.grayBackground3;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-grayBackground3" : Colors.grayBackground3;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white50" : Colors.white50;
    }
    return result;
  } else if (classType == "bg-black6") {
    let result = isTailwind ? "bg-black6" : Colors.black6;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black6" : Colors.black6;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black6" : Colors.black6;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white20" : Colors.white20;
    }
    return result;
  } else if (classType == "bg-black") {
    let result = isTailwind ? "bg-black" : Colors.black;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "bg-btn-map") {
    let result = isTailwind ? "bg-black" : Colors.black;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-zooBlue" : Colors.zooBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-prefabBlack" : Colors.prefabBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-parkfulPink" : Colors.parkfulPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-castleBlack" : Colors.castleBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-resortPink" : Colors.resortPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black" : Colors.black;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-rooftopPink" : Colors.rooftopPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-spaBlue" : Colors.spaBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-beachPink" : Colors.beachPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl") {
      result = isTailwind ? "bg-cocktaylPink" : Colors.cocktaylPink;
    }
    return result;
  } else if (classType == "bg-gradient1") {
    let result = isTailwind ? "bg-gradient1" : Colors.gradient1;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-zooBlue" : Colors.zooBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-prefabBlack" : Colors.prefabBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-parkfulPink" : Colors.parkfulPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-casinoRed" : Colors.casinoRed;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-castleBlack" : Colors.castleBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-resortPink" : Colors.resortPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-aparthotelPink" : Colors.aparthotelPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-rooftopPink" : Colors.rooftopPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-spaBlue" : Colors.spaBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-beachPink" : Colors.beachPink;
    }
    return result;
  } else if (classType == "placeholder-input") {
    let result = "placeholder:text-white3";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "placeholder:text-white3";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "placeholder:text-white3";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "placeholder:text-spliterLine";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "placeholder:text-spliterLine";
    }
    return result;
  } else if (classType == "bg-gradient-black") {
    let result = "bg-gradient-to-b from-gradient4 to-gradient5";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "bg-gradient-to-b from-gradient4 to-gradient5";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "bg-gradient-to-b from-gradient4 to-gradient5";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "bg-white80";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "bg-white80";
    }
    return result;
  } else if (classType == "text-whiteHalf") {
    let result = isTailwind ? "text-whiteHalf" : Colors.whiteHalf;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-whiteHalf" : Colors.whiteHalf;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-whiteHalf" : Colors.whiteHalf;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    }
    return result;
  } else if (classType == "bg-black4") {
    let result = isTailwind ? "bg-black4" : Colors.black4;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black4" : Colors.black4;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "bg-black8") {
    let result = isTailwind ? "bg-black8" : Colors.black8;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black8" : Colors.black8;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black8" : Colors.black8;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white80" : Colors.white80;
    }
    return result;
  } else if (classType == "hover:bg-black8") {
    let result = "hover:bg-black8";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "hover:bg-black8";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "hover:bg-black8";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "hover:bg-black6";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "hover:bg-black6";
    }
    return result;
  } else if (classType == "border-spliterLine") {
    let result = isTailwind ? "border-spliterLine" : Colors.spliterLine;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "border-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "border-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "custom-gradient") {
    let result = "custom-gradient";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "bg-zooBlue";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "bg-prefabBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "bg-parkfulPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "bg-casinoRed";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "bg-castleBlack";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "bg-resortPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "bg-aparthotelPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "bg-rooftopPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "bg-spaBlue";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "bg-beachPink";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl") {
      result = "bg-cocktaylPink";
    }
    return result;
  } else if (classType == "bg-black7") {
    let result = isTailwind ? "bg-black7" : Colors.black7;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black7" : Colors.black7;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black7" : Colors.black7;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-white" : Colors.white;
    }
    return result;
  } else if (classType == "bg-black2") {
    let result = isTailwind ? "bg-black2" : Colors.black2;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-lightGray") {
    let result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-black2" : Colors.black2;
    }
    return result;
  } else if (classType == "border-white2") {
    let result = "border-white2";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "border-white2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "border-white2";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "border-black25";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "border-black25";
    }
    return result;
  } else if (classType == "btn-border") {
    let result = "border border-tabBottomColor";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "border border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "border border-tabBottomColor";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "border border-white bg-white";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "border border-white bg-white";
    }
    return result;
  } else if (classType == "btn-border2") {
    let result = {
      borderWidth: 1,
      borderColor: Colors.spliterLine,
    };
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = {
        borderWidth: 1,
        borderColor: Colors.prefabBlack,
        backgroundColor: Colors.prefabBlack,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = {
        borderWidth: 1,
        borderColor: Colors.prefabBlack,
        backgroundColor: Colors.prefabBlack,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl") {
      result = {
        borderWidth: 1,
        borderColor: Colors.white,
        backgroundColor: Colors.white,
      };
    }
    return result;
  } else if (classType == "border-card-landscape") {
    let result = {};
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = {
        borderWidth: 1,
        borderColor: "#B5B5B533",
        height: 102,
      };
    }
    return result;
  } else if (classType == "border-tabBottomColor") {
    let result = isTailwind ? "border-tabBottomColor" : Colors.tabBottomColor;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "border-tabBottomColor" : Colors.tabBottomColor;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "border-tabBottomColor" : Colors.tabBottomColor;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "border-lightGray" : Colors.lightGray;
    }
    return result;
  } else if (classType == "bg-listItemActive") {
    let result = "rgba(255, 255, 255, 0.06)";
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = "rgba(255, 255, 255, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = "rgba(255, 255, 255, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = "rgba(0, 0, 0, 0.06)";
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = "rgba(0, 0, 0, 0.06)";
    }
    return result;
  } else if (classType == "bg-arrow-top") {
    let result = isTailwind ? "bg-white" : Colors.white;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "bg-zooBlue" : Colors.zooBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "bg-prefabBlack" : Colors.prefabBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "bg-parkfulPink" : Colors.parkfulPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "bg-casinoRed" : Colors.casinoRed;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "bg-castleBlack" : Colors.castleBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "bg-resortPink" : Colors.resortPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "bg-aparthotelPink" : Colors.aparthotelPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "bg-rooftopPink" : Colors.rooftopPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "bg-spaBlue" : Colors.spaBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "bg-beachPink" : Colors.beachPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl") {
      result = isTailwind ? "bg-cocktaylPink" : Colors.cocktaylPink;
    }
    return result;
  } else if (classType == "btn-text-contact") {
    let result = isTailwind ? "text-white" : Colors.white;
    if (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia") {
      result = isTailwind ? "text-spliterLine" : Colors.spliterLine;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld") {
      result = isTailwind ? "text-prefabBlack" : Colors.prefabBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Parkful") {
      result = isTailwind ? "text-parkfulPink" : Colors.parkfulPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") {
      result = isTailwind ? "text-castleBlack" : Colors.castleBlack;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club") {
      result = isTailwind ? "text-resortPink" : Colors.resortPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub") {
      result = isTailwind ? "text-white" : Colors.white;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub") {
      result = isTailwind ? "text-rooftopPink" : Colors.rooftopPink;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "SpaClub") {
      result = isTailwind ? "text-spaBlue" : Colors.spaBlue;
    } else if (process.env.NEXT_PUBLIC_APP_NAME == "Beachful") {
      result = isTailwind ? "text-white" : Colors.white;
    }
    return result;
  } else {
    return "";
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function pluralFormat(string = "days", amount) {
  return amount > 1 ? string : string.substring(0, string.length - 1);
}

export function diffFormat(startDate, endDate, type = "days") {
  const delta = calculateMilisecondDiff(startDate, endDate);
  if (delta > 86400000 * 2) {
    return "+3 Days";
  }
  if (delta > 86400000) {
    return "2 Days";
  }
  if (delta > 3600000 * 6) {
    return "6h-1 Day";
  }
  if (delta > 3600000 * 3) {
    return "3h-6h";
  }
  return "0-3h";
}

export function formatCurrency(currency, currencyType) {
  let currencySymbol = "$";
  if (currencyType == Constants.currencyType.POUND) {
    currencySymbol = "£";
  } else if (currencyType == Constants.currencyType.EUR) {
    currencyType = "€";
  }
  if (!currency) return "-";
  return `${currencySymbol}${currency?.toString()?.split(".")[0]}`;
}

export function formatNumber(count) {
  if (count > 1000000) {
    const milions = (count / 1000000).toFixed(1);
    return milions + "m";
  }
  if (count > 1000) {
    const thousands = Math.floor(count / 1000);
    return thousands + "k";
  }
  if (count === 0 || count === "0" || !count) {
    return "-";
  }
  return count?.toString()?.split(".")[0];
}

export function formatTagRange(tagCategory, value) {
  const findLabelByValue = (ranges, value) => {
    const range = ranges.find((r) => {
      if (
        r.value &&
        r.value.minValue !== undefined &&
        r.value.maxValue !== undefined
      ) {
        return value >= r.value.minValue && value <= r.value.maxValue;
      } else if (r.value && r.value.minValue !== undefined) {
        return value >= r.value.minValue;
      }
    });

    return range ? range.label : "N/A";
  };

  if (tagCategory === Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES) {
    const ranges = [
      {
        id: 0,
        label: "N/A",
        value: null,
      },
      {
        id: 1,
        label: "0-10",
        value: { minValue: 0, maxValue: 10 },
      },
      {
        id: 2,
        label: "11-50",
        value: { minValue: 11, maxValue: 50 },
      },
      {
        id: 3,
        label: "51-100",
        value: { minValue: 51, maxValue: 100 },
      },
      {
        id: 4,
        label: "101-200",
        value: { minValue: 101, maxValue: 200 },
      },
      {
        id: 5,
        label: "201-500",
        value: { minValue: 201, maxValue: 500 },
      },
      {
        id: 6,
        label: "+500",
        value: { minValue: 500 },
      },
    ];
    return findLabelByValue(ranges, value);
  } else if (
    tagCategory === Constants.tagCategory.TAG_CATEGORY_ZOO_POPULATION
  ) {
    const ranges = [
      {
        id: 0,
        label: "N/A",
        value: null,
      },
      {
        id: 1,
        label: "0-100",
        value: { minValue: 0, maxValue: 100 },
      },
      {
        id: 2,
        label: "100-500",
        value: { minValue: 100, maxValue: 500 },
      },
      {
        id: 3,
        label: "500-1,000",
        value: { minValue: 500, maxValue: 1000 },
      },
      {
        id: 4,
        label: "1,000-2,000",
        value: { minValue: 1000, maxValue: 2000 },
      },
      {
        id: 5,
        label: "+2,000",
        value: { minValue: 2000 },
      },
    ];
    return findLabelByValue(ranges, value);
  } else if (tagCategory === Constants.tagCategory.TAG_CATEGORY_SIZE_SQFT) {
    const ranges = [
      {
        id: 0,
        label: "N/A",
        value: null,
      },
      {
        id: 1,
        label: "XS",
        value: { minValue: 0, maxValue: 10000 },
      },
      {
        id: 2,
        label: "S",
        value: { minValue: 10000, maxValue: 50000 },
      },
      {
        id: 3,
        label: "M",
        value: { minValue: 50000, maxValue: 200000 },
      },
      {
        id: 4,
        label: "L",
        value: { minValue: 200000, maxValue: 500000 },
      },
      {
        id: 5,
        label: "XL",
        value: { minValue: 500000, maxValue: 1000000 },
      },
      {
        id: 6,
        label: "XXL",
        value: { minValue: 1000000 },
      },
    ];
    return findLabelByValue(ranges, value);
  }
  return value;
}

export function formatPeriod(
  startDate,
  endDate,
  formatString = "YYYY-MM-DD",
  timezone = ""
) {
  return (
    formatDate(startDate, formatString, true) +
    " - " +
    formatDate(endDate, formatString, true) +
    " " +
    timezone
  );
}
export function getDisplayName(profile) {
  if (!profile) return;
  if (profile.displayName) return profile.displayName;
  if (profile.nickname) return profile.nickname;
  if (profile.firstName || profile.lastName)
    return profile.firstName + " " + profile.lastName;
  if (profile.username) return profile.username;
  return "User";
}

export function formatTime(s) {
  // Hours, minutes and seconds
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export function getSpecialist(genres) {
  if (!genres) return "";
  if (genres.length == 0) return "";
  if (genres.length > 1) {
    return (genres[0].title || genres[0]) + " +" + (genres.length - 1);
  }
  if (genres.length == 1) {
    return genres[0].title || genres[0];
  }
  return "";
}

export const nthNumber = (number) => {
  if (number > 3 && number < 21) return number + "th";
  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};
