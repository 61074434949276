import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../config";
import { useRouter } from "next/router";
import CImage from "../CImage";
import { getClass } from "../../../helpers/formatHelpers";

const NavigationBar = (props) => {
  const navigation = useRouter();
  const { isMobileVersion, fontSize } = props;
  const onBackPressed = () => {
    if (props.onBackPressed) {
      props.onBackPressed();
    } else {
      navigation.back();
    }
  };
  let title = props.title || "";
  if (title && title != "") {
    title = title.toUpperCase();
  }
  const { hideBack } = props;

  return (
    <View
      style={[
        styles.header,
        isMobileVersion
          ? { height: 48, backgroundColor: "transparent", marginBottom: 16 }
          : { marginVertical: 30 },
        props.customStyle,
      ]}>
      {props.customContent ? (
        props.customContent
      ) : (
        <View style={styles.titleContainer}>
          <Text
            style={[
              styles.titleText,
              isMobileVersion
                ? { fontSize: fontSize || 14 }
                : { color: Colors.white },
            ]}>
            {props.withoutHeading ? title : <h1>{title}</h1>}
          </Text>
        </View>
      )}

      <View style={styles.leftButtonContainer}>
        {!hideBack && (
          <TouchableOpacity
            onPress={onBackPressed}
            style={{
              width: 40,
              height: isMobileVersion ? 40 : 60,
              justifyContent: "center",
              alignItems: "center",
            }}>
            <CImage
              height={24}
              width={24}
              layout="fixed"
              source={
                props.backIcon ||
                (isMobileVersion
                  ? Images.iconAngleLeft
                  : Images.iconAngleLeftWhite)
              }
              style={{ width: 24, height: 24 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.rightButtonContainer}>
        {props.rightButtonContainer}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    height: 70,
    alignItems: "center",
    zIndex: 100,
    backgroundColor: getClass("bg-gray2"),
    borderRadius: 100,
  },
  titleContainer: {
    flex: 1,
    marginHorizontal: 50,
    alignItems: "center",
    justifyContent: "center",
    padding: 4,
  },
  titleText: {
    color: getClass("text-white"),
    fontSize: 24,
    fontFamily: Fonts.primaryBold,
  },

  leftButtonContainer: {
    position: "absolute",
    left: 10,
    top: 4,
  },
  rightButtonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  whiteIcon: { color: "#FFF" },
});

export default NavigationBar;
