import { decode, encode } from 'base-64'
export function jwtDecode(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    decode(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
export const verifyToken = (token) => {
  if (!token) return false
  try {
    const { exp } = jwtDecode(token)
    if (Date.now() >= exp * 1000) {
      return false
    }
  } catch (err) {
    return false
  }
  return true
}
