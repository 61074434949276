import React from "react";
import PropTypes from "prop-types";
import {
  View,
  Platform,
  StyleSheet,
  TextInput,
  Image,
  Text,
  TouchableOpacity,
} from "react-native-web";

import { Fonts, Colors, Images } from "../../config";
import { t } from "i18next";
import CPercentageCircle from "./CPercentageCircle";
class CTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }
  focus = () => {
    this.textInput.focus();
  };
  renderRemaining = (currentLength, maxLength) => {
    const percent = Math.floor((currentLength / maxLength) * 100);
    return (
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 5 }}
      >
        <Text style={[styles.remainingText, { marginRight: 4 }]}>
          {t("key_remaining")}
        </Text>
        <CPercentageCircle
          percent={percent}
          radius={5}
          borderWidth={1}
          color={Colors.white}
          shadowColor={Colors.grey}
          bgColor={Colors.black}
        />
        <Text
          style={[styles.remainingText, { color: Colors.white, marginLeft: 4 }]}
        >
          {currentLength}
        </Text>
        <Text style={styles.remainingText}>{"/" + maxLength}</Text>
      </View>
    );
  };
  onChangeText = (text) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(text);
    }
    this.setState({ isEditing: true });
  };
  onEndEditing = (e) => {
    if (this.props.onEndEditing) {
      this.props.onEndEditing(e);
    }
    this.setState({ isEditing: false });
  };
  render() {
    const { props } = this;
    const { maxLength, showRemaining } = props;
    const currentLength = props.value ? props.value.length : 0;
    const isShowRemaining = showRemaining && this.state.isEditing;

    return (
      <View style={[styles.container, props.containerStyle]}>
        <TextInput
          multiline
          numberOfLines={4}
          textAlignVertical="top"
          placeholderTextColor={
            props.placeholderTextColor || Colors.placeholder
          }
          {...props}
          onChangeText={this.onChangeText}
          onEndEditing={this.onEndEditing}
          style={[styles.inputStyle, props.inputStyle]}
        />
        {isShowRemaining && this.renderRemaining(currentLength, maxLength)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  inputContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  inputStyle: {
    minHeight: 64,
    maxHeight: 120,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white,
  },
  remainingText: {
    color: Colors.grey,
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryRegular,
  },
});

export default CTextArea;
