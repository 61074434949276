import React from "react";
import { Text } from "react-native-web";
// import MaskedView from "@react-native-community/masked-view";
import LinearGradientView from "./LinearGradientView";

const GradientText = (props) => {
  return (
    // <MaskedView maskElement={<Text {...props} />}>
    <LinearGradientView>
      <Text {...props} style={[props.style, { opacity: 0 }]} />
    </LinearGradientView>
    // </MaskedView>
  );
};

export default GradientText;
