import { StyleSheet, Text, View, Image } from "react-native-web";
import React from "react";
import { Colors, Fonts, Images } from "../../../config";
import moment from "moment";

const NotificationsComponent = (props) => {
  const type = props.type;

  if (type == "Likes") {
    return (
      <View style={styles.container}>
        <View style={{}}>
          <Image source={Images.iconPhoto} style={styles.photo} />
          <Image source={Images.iconLikeCircleBorder} style={styles.iconSec} />
        </View>
        <View style={{ paddingLeft: 20, flex: 1 }}>
          <Text style={styles.name}>
            {props.name}
            <Text style={styles.modeText}>
              {" "}
              liked your
              <Text style={styles.postText}> post</Text>
            </Text>
          </Text>
          <Text style={styles.timeText}>2h</Text>
        </View>
      </View>
    );
  } else if (type == "Comments") {
    return (
      <View style={styles.container}>
        <View style={{}}>
          <Image source={Images.iconPhoto} style={styles.photo} />
          <Image
            source={Images.iconCommentCircleBorder}
            style={styles.iconSec}
          />
        </View>
        <View style={{ paddingLeft: 20, flex: 1 }}>
          <Text style={styles.name}>
            {props.name}
            <Text style={styles.modeText}>
              {" "}
              commented your
              <Text style={styles.postText}> post</Text>
            </Text>
          </Text>
          <Text style={styles.timeText}>2h</Text>
        </View>
      </View>
    );
  } else if (type == "Share") {
    return (
      <View style={styles.container}>
        <View style={{}}>
          <Image source={Images.iconPhoto} style={styles.photo} />
          <Image source={Images.iconShareCircleBorder} style={styles.iconSec} />
        </View>
        <View style={{ paddingLeft: 20, flex: 1 }}>
          <Text style={styles.name}>
            {props.name}
            <Text style={styles.modeText}>
              {" "}
              shared your
              <Text style={styles.postText}> post</Text>
            </Text>
          </Text>
          <Text style={styles.timeText}>2h</Text>
        </View>
      </View>
    );
  } else if (type == "Follow") {
    return (
      <View style={styles.container}>
        <View style={{}}>
          <Image source={Images.iconPhoto} style={styles.photo} />
          <Image
            source={Images.iconFollowCircleBorder}
            style={styles.iconSec}
          />
        </View>
        <View style={{ paddingLeft: 20, flex: 1 }}>
          <Text style={styles.name}>Arlene McCoy, MJ Flores and 6 others</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.followText}>started following you </Text>
            <Text style={styles.timeText}>2h</Text>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        {/* <View style={{}}>
          <Image source={Images.iconPhoto} style={styles.photo} />
          <Image
            source={Images.iconFollowCircleBorder}
            style={styles.iconSec}
          />
        </View> */}
        <View style={{ paddingLeft: 0, flex: 1 }}>
          <Text style={styles.postText}>{props.name}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.timeText}>{moment(props.date).fromNow()}</Text>
          </View>
        </View>
      </View>
    );
  }
};

export default NotificationsComponent;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Colors.darkGrayCard,
    borderRadius: 16,
    paddingHorizontal: 14,
    paddingVertical: 8,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  photo: {
    width: 32,
    height: 32,
  },
  iconSec: {
    width: 18,
    height: 18,
    position: "absolute",
    right: -8,
    bottom: 0,
  },
  name: {
    fontSize: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    lineHeight: 15,
  },
  timeText: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: Colors.grayText2,
    lineHeight: 15,
    marginTop: 1,
  },
  followText: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white80,
    lineHeight: 15,
    marginTop: 1,
  },
  modeText: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white60,
    lineHeight: 15,
    marginTop: 1,
  },
  postText: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white80,
    lineHeight: 15,
    marginTop: 1,
  },
});
