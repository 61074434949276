import React, { useState, useEffect } from "react";
import { View, StyleSheet, Platform } from "react-native-web";
// import { Calendar } from "react-native-calendars";
import { Colors, Fonts, Images } from "../../config";
import Calendar from "react-calendar";
import { differenceInCalendarDays } from "date-fns";
import CImage from "./CImage";
import moment from "moment";

const Arrow = (props) => {
  const { isMobileVersion } = props;
  const imageIcon =
    props.direction == "left"
      ? Images.iconAngleLeftWhite
      : Images.iconAngleRight;
  return (
    <View
      style={[
        styles.arrowContainer,
        isMobileVersion ? { width: 18, height: 18 } : {},
      ]}>
      <CImage
        source={imageIcon}
        width={isMobileVersion ? 12 : 16}
        height={isMobileVersion ? 12 : 16}
        layout="fixed"
      />
    </View>
  );
};
const CCalendar = (props) => {
  const { isMobileVersion } = props;
  const [datesToAddContentTo, setDatesToCalendar] = useState([]);

  useEffect(() => {
    initView();
  }, [JSON.stringify(props.markedDates)]);

  const initView = () => {
    var dateLists = [];
    if (props.markedDates) {
      Object.keys(props.markedDates).forEach((key) => {
        var tmp = key.split("-");
        if (tmp.length == 3) {
          dateLists.push(new Date(tmp[0], parseInt(tmp[1]) - 1, tmp[2]));
        }
      });
    }

    setDatesToCalendar(dateLists);
  };

  const saved = {
    key: "saved",
    color: Colors.white,
    selectedColor: Colors.white,
  };

  const going = {
    key: "going",
    color: Colors.grayText,
    selectedColor: Colors.grayText,
  };

  //const datesToAddContentTo = [ new Date(2022, 10, 1),  new Date(2022, 10, 3),  new Date(2022, 10, 6)];

  function tileClassName({ date, view }) {
    // Check if a date React-Calendar wants to check is on the list of dates to add class to
    if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
      return "myClassName";
    }
  }
  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  return (
    <View style={{ alignItems: "center", fontSize: 12 }}>
      <Calendar
        calendarType="US"
        className={`${props.isSmall ? "small-calendar" : ""}${
          isMobileVersion ? " mobile-responsive" : ""
        }`}
        onChange={(value) => props.onDayPress(value)}
        value={
          props.withRange ? undefined : moment(props.dateSelected).toDate()
        }
        selectRange={props.withRange}
        tileClassName={tileClassName}
        nextLabel={
          <Arrow direction="right" isMobileVersion={isMobileVersion} />
        }
        prevLabel={<Arrow direction="left" isMobileVersion={isMobileVersion} />}
        minDetail="month"
        formatMonthYear={(locale, date) =>
          props.isSmall
            ? isMobileVersion
              ? `${moment(props.dateSelected).format("ddd, DD")} ${moment(
                  date
                ).format("MMMM YYYY")}`
              : moment(date).format("MMMM YYYY")
            : `${moment(props.dateSelected).format("ddd, DD")} ${moment(
                date
              ).format("MMMM YYYY")}`
        }
      />
    </View>
    //<Calendar
    //markingType={"custom"}
    //renderArrow={(direction) => <Arrow direction={direction} />}
    //monthFormat={"ddd, dd MMMM yyyy"}
    // theme={{
    //   backgroundColor: Colors.black,
    //   calendarBackground: Colors.black,
    //   textSectionTitleColor: Colors.white,
    //   textSectionTitleDisabledColor: Colors.white80,
    //   selectedDayBackgroundColor: "transparent",
    //   selectedDayTextColor: Colors.white,
    //   todayTextColor: Colors.white,
    //   dayTextColor: Colors.white,
    //   textDisabledColor: Colors.white,
    //   monthTextColor: Colors.white,
    //   indicatorColor: Colors.white,
    //   textDayFontFamily: Fonts.primaryRegular,
    //   textMonthFontFamily: Fonts.primaryBold,
    //   textMonthFontWeight: "700",

    //   textDayHeaderFontFamily: Fonts.primaryBold,
    //   textDayfontSize: 16,
    //   textMonthfontSize: 16,
    //   textDayHeaderfontSize: 16,
    //   textDayStyle: styles.dayStyle,
    //   dotStyle: styles.dotStyle,
    //   arrowColor: "white",
    //   "stylesheet.calendar.header": {
    //     header: styles.header,
    //     week: styles.weekContainer,
    //   },
    //   "stylesheet.calendar.main": {
    //     container: styles.container,
    //     week: styles.dayRowContainer,
    //   },
    //   "stylesheet.day.basic": {
    //     today: styles.today,
    //     selected: styles.selectedDayContainer,
    //     base: styles.dayContainer,
    //   },
    // }}
    //   {...props}
    // />
  );
};
const styles = StyleSheet.create({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  selectedDayText: {
    color: Colors.white,
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryBold,
  },
  dayStyle: {
    marginTop: Platform.OS === "ios" ? 2 : 0,
  },
  dotStyle: {
    width: 2,
    height: 2,
  },
  today: {
    borderWidth: 1,
    borderColor: Colors.blue,
    borderRadius: 16,
  },
  dayContainer: {
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedDayContainer: {
    backgroundColor: Colors.gradient2,
    borderRadius: 16,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: "center",
    height: 36,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.white10,
  },
  weekContainer: {
    height: 32,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    borderBottomColor: Colors.white10,
  },
  dayRowContainer: {
    justifyContent: "space-around",
    flexDirection: "row",
    marginVertical: 7,
  },
  arrowContainer: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderRadius: 60,
    borderColor: Colors.grayBorder,
    alignItems: "center",
    justifyContent: "center",
  },
  arrowIcon: {
    width: 12,
    height: 12,
  },
});
export default CCalendar;
