import moment from "moment";
import slugify from "slugify";
import { Constants } from "../src/config";

export const nFormatter = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "m";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return num;
};

export const getCityCountryString = (city, country, separator = ", ") => {
  const _address = [];
  if (city && city != "") {
    _address.push(city);
  }
  if (country && country != "") {
    _address.push(country);
  }
  return _address.join(separator);
};

export const getArrayToString = (array = [], key, separator = ", ") => {
  const result = [];
  array.forEach((item) => {
    result.push(item[key]);
  });
  return result.join(separator);
};

export function getSlugFromName(name, startDate) {
  if (!name) return "festival";
  let slug = slugify(`${name}-${moment(startDate).format("YYYY")}`, {
    lower: true,
    strict: true,
    remove: /[*+~.()'"!:@]/g,
  });
  return slug;
}
export function generateURLSlug(id, name, startDate, category, tabType) {
  let slug = slugify(
    `${name || "unknown"}${
      startDate ? `-${moment(startDate).format("YYYY")}` : ""
    }`,
    {
      lower: true,
      strict: true,
      remove: /[*+~.()'"!:@]/g,
    }
  );
  const categoryText =
    category === Constants.categoryType.CATEGORY_ARTIST
      ? "artists"
      : category === Constants.categoryType.CATEGORY_VENUE
      ? "venues"
      : category === Constants.categoryType.CATEGORY_ROOFTOP
      ? "rooftops"
      : category === Constants.categoryType.CATEGORY_SPA
      ? "spas"
      : category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL
      ? "beach-clubs"
      : category === Constants.categoryType.CATEGORY_PREFAB_COMPANY
      ? "companies"
      : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
      ? "homes"
      : category === Constants.categoryType.CATEGORY_ZOO
      ? "zoos"
      : category === Constants.categoryType.CATEGORY_ANIMAL
      ? "animals"
      : category === Constants.categoryType.CATEGORY_PARKFUL
      ? "parks"
      : category === Constants.categoryType.CATEGORY_CASINO
      ? "casinos"
      : category === Constants.categoryType.CATEGORY_CASTLE
      ? "castles"
      : category === Constants.categoryType.CATEGORY_RESORT
      ? "resorts"
      : category === Constants.categoryType.CATEGORY_APARTHOTEL
      ? "aparthotels"
      : category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL
      ? "bars"
      : category === Constants.categoryType.CATEGORY_EVENT
      ? "events"
      : "festivals";
  return `/${categoryText}/${
    slug
      ? slug
      : category === Constants.categoryType.CATEGORY_ARTIST
      ? "artist-name"
      : category === Constants.categoryType.CATEGORY_VENUE
      ? "venue-name"
      : category === Constants.categoryType.CATEGORY_ROOFTOP
      ? "rooftop-name"
      : category === Constants.categoryType.CATEGORY_SPA
      ? "spa-name"
      : category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL
      ? "venue-beachful-name"
      : category === Constants.categoryType.CATEGORY_PREFAB_COMPANY
      ? "company-name"
      : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
      ? "home-name"
      : category === Constants.categoryType.CATEGORY_ZOO
      ? "zoo-name"
      : category === Constants.categoryType.CATEGORY_ANIMAL
      ? "animal-name"
      : category === Constants.categoryType.CATEGORY_PARKFUL
      ? "park-name"
      : category === Constants.categoryType.CATEGORY_CASINO
      ? "casino-name"
      : category === Constants.categoryType.CATEGORY_CASTLE
      ? "castle-name"
      : category === Constants.categoryType.CATEGORY_RESORT
      ? "resort-name"
      : category === Constants.categoryType.CATEGORY_APARTHOTEL
      ? "aparthotel-name"
      : category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL
      ? "venue-cocktail-name"
      : category === Constants.categoryType.CATEGORY_EVENT
      ? "event-name"
      : "festival-name"
  }/${id}${tabType ? `/${tabType}` : "/about"}`;
}
export function generateLinkURL(url, queryBody) {
  if (!queryBody) return url;
  const queryAttributes = [];
  Object.keys(queryBody).forEach((keyVal) => {
    queryAttributes.push(`${keyVal}=${queryBody[keyVal]}`);
  });
  if (queryAttributes.length > 0) {
    return url + "?" + queryAttributes.join("&");
  }
  return url;
}
