import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";
import { getClass } from "../../helpers/formatHelpers";

const ReadMoreText = (props) => {
  const { t } = useTranslation();
  const {
    isMobileVersion,
    withoutButton,
    minVisibleLines = 6.2,
    maxLength = 680,
    isDisplayPreview,
  } = props;
  const MAX_LENGTH = maxLength;
  const MIN_VISIBLE_LINES = minVisibleLines; // minimum visible lines of text
  const LINE_HEIGHT = isMobileVersion ? 20 : 24; // line height in pixels
  const contentRef = useRef(null);
  const [numVisibleLines, setNumVisibleLines] = useState(MIN_VISIBLE_LINES);
  const [minHeight, setMinHeight] = useState(MIN_VISIBLE_LINES * LINE_HEIGHT);

  const [isReadMore, setIsReadMore] = useState(true);

  const isVisibleReadMore =
    props.text?.replace(/(<([^>]+)>)/gi, "").length > MAX_LENGTH;

  const handleResize = useCallback(
    (event) => {
      if (contentRef.current) {
        if (isReadMore) {
          const containerHeight = event?.target?.offsetHeight;
          const minContentHeight = contentRef?.current?.offsetHeight;
          const numVisibleLines = Math.floor(containerHeight / LINE_HEIGHT);
          setNumVisibleLines(numVisibleLines);
          setMinHeight(minContentHeight);
        } else {
          setNumVisibleLines(MIN_VISIBLE_LINES);
          setMinHeight(MIN_VISIBLE_LINES * LINE_HEIGHT);
        }
      }
    },
    [contentRef, isReadMore]
  );

  useEffect(() => {
    const observer = new ResizeObserver(handleResize);
    observer?.observe(contentRef.current);
  }, [handleResize]);

  return (
    <View style={[styles.container, props.style]}>
      <div
        className={`read-more-text-container ${
          isReadMore && !isDisplayPreview ? "read-more-text-mask" : ""
        } ${
          isDisplayPreview
            ? isMobileVersion
              ? "display-preview-mobile"
              : "display-preview"
            : ""
        }`}
        ref={contentRef}
        style={{
          color: getClass("text-white"),
          fontFamily: Fonts.primaryRegular,
          fontSize: isMobileVersion ? 12 : 16,
          lineHeight: `${LINE_HEIGHT}px`,
          height:
            isReadMore && isVisibleReadMore
              ? `${
                  numVisibleLines *
                  (isMobileVersion ? LINE_HEIGHT + 4 : LINE_HEIGHT)
                }px`
              : "auto",
          overflow: "hidden",
        }}
        dangerouslySetInnerHTML={{
          __html: props.text?.replace(
            /<p>&nbsp;<\/p>|<p> <\/p>|<p> <\/p>|<p><\/p>/g,
            ""
          ),
        }}></div>
      {isVisibleReadMore && !withoutButton && (
        <TouchableOpacity
          style={[
            styles.buttonContainer,
            isReadMore
              ? {
                  marginTop: 0,
                }
              : { marginTop: 15 },
          ]}
          onPress={() => {
            setIsReadMore(!isReadMore);
          }}>
          <Text
            style={[
              styles.button,
              isMobileVersion ? { fontSize: 12 } : {},
              props.buttonStyle,
            ]}>
            {isReadMore ? t("key_readmore") : t("key_readless")}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { paddingHorizontal: 16, textAlign: "left" },
  buttonContainer: {
    borderWidth: 1,
    borderRadius: 50,
    borderColor: getClass("border-spliterLine"),
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: 24,
    alignSelf: "center",
    width: 150,
  },
  button: {
    color: getClass("text-white"),
    fontFamily: Fonts.primaryBold,
    fontSize: 14,
    lineHeight: 16,
    textAlign: "center",
  },
});

export default ReadMoreText;
