import React, { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";

const CollapsableView = (props) => {
  const { t } = useTranslation();
  const { isCollapsed, title, text } = props;
  const onPressView = () => {
    if (props.onPressView) {
      props.onPressView();
    }
  };
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={onPressView}
      style={[
        styles.container,
        !isCollapsed && styles.expandedContainer,
        props.style,
      ]}
    >
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
        <Image
          style={styles.icon}
          source={
            isCollapsed ? Images.iconArrowExpanded : Images.iconArrowCollapsed
          }
        />
      </View>
      {!isCollapsed && (
        <View style={styles.contentContainer}>
          {text && <Text style={styles.text}>{text}</Text>}
          {props.children}
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.grayBackground,
    borderRadius: 16,
    alignSelf: "stretch",
    overflow: "hidden",
    padding: 16,
    paddingLeft: 18,
  },
  expandedContainer: {
    paddingTop: 20,
  },
  titleContainer: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    lineHeight: 15,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  text: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white80,
  },
  icon: {
    width: 16,
    height: 8,
  },
});

export default CollapsableView;
