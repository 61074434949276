import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../config";
import { useRouter } from "next/router";

const BottomBorderTabItem = (props) => {
  const navigation = useRouter();

  const onSelectTab = (item, index) => {
    if (props.onSelectTab) {
      props.onSelectTab(item, index);
    }
  };
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        onSelectTab(props.item, props.index);
      }}>
      <View style={[styles.tabItemContainer, props.style]}>
        <Text
          style={
            props.isPicked ? styles.selectedTabItemText : styles.tabItemText
          }>
          {props.item.title}
        </Text>
        {props.isPicked && (
          <div
            className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
            style={{
              height: 2,
              width: "100%",
              position: "absolute",
              left: 0,
              bottom: 0,
              borderRadius: 1,
              zIndex: 100,
            }}
          />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    position: "absolute",
    left: 16,
    top: 17,
    width: 24,
    height: 24,
  },
  tabItemContainer: {
    height: 45,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  selectedTabItemText: {
    fontSize: 16,
    lineHeight: 14,
    color: "white",
    fontFamily: Fonts.primaryBold,
  },
  tabItemText: {
    fontSize: 16,
    lineHeight: 14,
    color: "white",
    fontFamily: Fonts.primaryBold,
  },
});

export default BottomBorderTabItem;
