import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native-web";
import CImage from "./CImage";
import { Images } from "../../config";
const CAvatar = (props) => {
  const size = props.size || 16;
  const urlAvailable = props.url != null && props.url != "";
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}
      style={[
        styles.container,
        {
          width: size,
          height: size,
          borderRadius: size / 2,
        },
        props.style,
      ]}>
      {urlAvailable ? (
        <CImage
          imgUrl={props.url}
          alt={props.alt}
          width={size}
          height={size}
          style={styles.avatarImage}
        />
      ) : (
        <CImage
          source={Images.iconAvatarDefault}
          alt={props.alt}
          width={size}
          height={size}
          style={styles.avatarImage}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 16,
    height: 16,
    overflow: "hidden",
    borderRadius: 8,
  },
  avatarImage: {
    width: "100%",
    height: "100%",
  },
});

export default CAvatar;
