import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  FlatList,
  Keyboard,
  Modal,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Text,
} from "react-native-web";
import IconizedTextInput from "./IconizedTextInput";
import CImage from "./CImage";

import { Colors, Fonts, Images } from "../../config";

class IconizedSingleItemPicker extends Component {
  constructor(props) {
    super(props);
    let text = "";
    var items = props.data;
    if (props.bottomAdditionalItems) {
      items = items.concat(props.bottomAdditionalItems);
    }
    text = this.getSelectedTextFromId(props.selectedItemID, items);

    this.state = {
      isVisible: false,
      items: items,
      text: text,
      selectedItemID: props.selectedItemID,
    };
  }

  componentWillReceiveProps(nextProps) {
    var items = nextProps.data;
    if (nextProps.bottomAdditionalItems) {
      items = items.concat(nextProps.bottomAdditionalItems);
    }
    this.setState({ items, selectedItemID: nextProps.selectedItemID }, () => {
      this.setSelectedText();
    });
  }

  onItemSelected(touchedItem) {
    this.setState({ selectedItemID: touchedItem.id }, () => {
      this.setState({ text: touchedItem.name });
    });
    if (this.props.onItemSelected) {
      this.props.onItemSelected(touchedItem);
    }
    this.dismiss();
  }

  setSelectedText() {
    const { items, selectedItemID } = this.state;
    this.setState({ text: this.getSelectedTextFromId(selectedItemID, items) });
  }
  getSelectedTextFromId = (selectedItemID, items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == selectedItemID) return items[i].name;
    }
    return "";
  };

  renderItem = ({ item }) => (
    <TouchableWithoutFeedback onPress={() => this.onItemSelected(item)}>
      <View
        style={{
          height: 40,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.spliterLine,
        }}
      >
        <Text style={styles.text}>{item.name}</Text>
      </View>
    </TouchableWithoutFeedback>
  );

  dismiss = () => {
    this.setState({ isVisible: false });
  };

  show = () => {
    this.setState({ isVisible: true });
  };

  render() {
    var items = this.props.data;
    if (this.props.bottomAdditionalItems) {
      items = items.concat(this.props.bottomAdditionalItems);
    }
    return (
      <View
        style={[
          { flexDirection: "row", alignItems: "center" },
          { ...this.props.itemPickerPlaceholder },
          this.props.pickerContainerStyle,
        ]}
      >
        <Modal
          animationType="slide"
          transparent
          visible={this.state.isVisible}
          onRequestClose={this.dismiss}
        >
          <TouchableWithoutFeedback onPress={this.dismiss}>
            <View style={styles.dim}>
              <View style={styles.container}>
                <CImage
                  style={styles.filterIcon}
                  resizeMode={"contain"}
                  source={Images.iconFilterList}
                  tintColor={Colors.white}
                  opacity={0.18}
                />
                {this.props.title && (
                  <Text style={styles.title}>{this.props.title}</Text>
                )}

                <FlatList
                  style={{ flex: 1 }}
                  renderItem={this.renderItem}
                  data={items}
                  keyExtractor={(item, index) => index.toString()}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
        <IconizedTextInput
          ref={(component) => {
            this.editText = component;
          }}
          value={this.state.text}
          isPicker
          {...this.props}
          dropdownIcon={Images.iconArrowExpanded}
          onOpenPicker={() => {
            Keyboard.dismiss();
            this.show();
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  dim: {
    backgroundColor: "rgba(89, 89, 89, 0.45)",
    flex: 1,
    justifyContent: "flex-end",
  },
  container: {
    margin: 0,
    padding: 10,
    paddingLeft: 16,
    paddingRight: 16,
    position: "absolute",
    width: "100%",
    backgroundColor: Colors.black,
    borderWidth: 0,
    maxHeight: 250,
    justifyContent: "center",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  title: {
    color: Colors.white,
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    marginTop: 8,
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
    flex: 1,
    color: Colors.white,
    fontFamily: Fonts.primaryRegular,
  },
  check: {
    width: 16,
    height: 16,
    marginStart: 10,
    marginEnd: 10,
  },
  listItem: {
    height: 40,
    paddingLeft: 4,
    paddingRight: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.spliterLine,
  },
  closeButton: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 50,
    alignSelf: "center",
  },
  filterIcon: {
    width: 24,
    height: 24,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
});

IconizedSingleItemPicker.propTypes = {
  data: PropTypes.array.isRequired,
  selectedItemID: PropTypes.any,
  title: PropTypes.string,
  onItemSelected: PropTypes.func.isRequired,
};

export default IconizedSingleItemPicker;
