import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import {
  StyleSheet,
  View,
  FlatList,
  Modal,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native-web";
import { Colors, Constants, Fonts, Images, Values } from "../../config";
import CImage from "./CImage";
import { getClass } from "../../helpers/formatHelpers";

const CModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const {
    hideClose,
    closableWithOutsideTouch,
    modalType,
    isMobileVersion,
    isSmall,
  } = props;
  const _modalType = modalType || Constants.modalType.MODAL_TYPE_CENTER;
  const isCenterModal = _modalType == Constants.modalType.MODAL_TYPE_CENTER;
  const isBottomModal = _modalType == Constants.modalType.MODAL_TYPE_BOTTOM;
  const isFullModal = _modalType == Constants.modalType.MODAL_TYPE_FULL;
  useImperativeHandle(ref, () => ({
    showModal: () => {
      setIsVisible(true);
      if (props.onShowModal) {
        props.onShowModal();
      }
      setTimeout(() => {
        document.body.style.overflow = "hidden";
      }, 50);
    },
    hideModal: () => {
      setIsVisible(false);
      document.body.style.overflow = "auto";
    },
  }));
  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setIsVisible(false);
    document.body.style.overflow = "auto";
  };
  return (
    <View style={[props.style]}>
      <Modal
        transparent
        avoidKeyboard
        visible={isVisible}
        onRequestClose={onClose}
        style={{ margin: 0 }}>
        <TouchableOpacity
          style={{
            backgroundColor: "rgba(89, 89, 89, 0.45)",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
          onPress={onClose}
        />
        <View
          style={[
            isCenterModal && styles.dim,
            isBottomModal && styles.bottomModalDim,
            isFullModal && styles.fullModalDim,
            props.maxWidth ? { maxWidth: props.maxWidth } : {},
          ]}>
          {closableWithOutsideTouch && (
            <TouchableOpacity
              activeOpacity={1}
              onPress={onClose}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          )}

          <View
            style={[
              isCenterModal && styles.modalContainer,
              isBottomModal && styles.bottomModalContainer,
              isFullModal && styles.fullModalContainer,
              isCenterModal && isMobileVersion ? { width: "90%" } : {},
              isMobileVersion && isSmall ? { width: 300, height: 82 } : {},
              props.maxWidth ? { maxWidth: props.maxWidth } : {},
            ]}
            activeOpacity={1}>
            <View
              style={[
                styles.bodyContainer,
                isMobileVersion ? { paddingTop: 20 } : {},
                isFullModal && { padding: 0, alignItems: undefined },
                isMobileVersion && isSmall
                  ? {
                      padding: 0,
                      paddingTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {},
              ]}>
              {!hideClose && (
                <TouchableOpacity
                  style={[
                    styles.closeIconContainer,
                    isMobileVersion ? { width: 20, height: 20, top: 16 } : {},
                  ]}
                  onPress={onClose}>
                  <CImage
                    source={Images.iconClose}
                    width={isMobileVersion ? 16 : 24}
                    height={isMobileVersion ? 16 : 24}
                    layout="fixed"
                    alt="close"
                  />
                </TouchableOpacity>
              )}

              {props.title || props.icon ? (
                <View style={styles.titleContainer}>
                  {props.icon && (
                    <View
                      style={[
                        { marginRight: isMobileVersion ? 8 : 15 },
                        props.iconStyle,
                      ]}>
                      <CImage
                        source={props.icon}
                        width={props.iconWidth || (isMobileVersion ? 16 : 24)}
                        height={props.iconHeight || (isMobileVersion ? 16 : 24)}
                        layout="fixed"
                        alt={props.title?.toLowerCase()}
                      />
                    </View>
                  )}
                  {props.title && (
                    <div>
                      <Text
                        style={[
                          styles.title,
                          isMobileVersion ? { fontSize: 16 } : {},
                        ]}>
                        {props.title}
                      </Text>
                    </div>
                  )}
                </View>
              ) : (
                <View style={[styles.titleContainer, { height: 20 }]}>
                  {props.title && (
                    <div>
                      <Text
                        style={[
                          styles.title,
                          isMobileVersion ? { fontSize: 16 } : {},
                        ]}></Text>
                    </div>
                  )}
                </View>
              )}
              {props.children}
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
});

const styles = StyleSheet.create({
  dim: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: 480,
    width: "100%",
    height: "auto",
  },
  modalContainer: {
    margin: 32,
    position: "absolute",
    flex: 1,
    backgroundColor: getClass("background"),
    borderWidth: 0,
    justifyContent: "center",
    borderRadius: 25,
    maxWidth: 480,
    width: "100%",
    zIndex: 500,
  },
  bottomModalDim: {
    justifyContent: "flex-end",
    flex: 1,
    alignItems: "center",
  },
  bottomModalContainer: {
    position: "absolute",
    width: "100%",
    justifyContent: "center",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    zIndex: 500,
    backgroundColor: Colors.black,
  },
  title: {
    fontFamily: Fonts.primaryBold,
    fontSize: 24,
    lineHeight: 20,
    color: getClass("text-white"),
    textAlign: "center",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  bodyContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 16,
    paddingBottom: 20,
    paddingTop: 30,
  },
  closeIconContainer: {
    position: "absolute",
    top: 20,
    right: 16,
    width: 32,
    height: 32,
  },
  fullModalDim: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.black,
  },
  fullModalContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.black,
  },
});
export default CModal;
