import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native-web";
import React, { useState } from "react";
import { Colors, Fonts, Images } from "../../../config";

const NotificationTopBarComponent = (props) => {
  const [left, setleft] = useState(true);
  const fontColor = [
    {
      fontSize: 16,
      color: Colors.white,
      fontFamily: Fonts.primaryBold,
    },
    {
      fontSize: 16,
      color: Colors.white30,
      fontFamily: Fonts.primaryRegular,
    },
  ];
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.topBarContainer}>
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            setleft(true);
          }}
        >
          <Text style={left ? fontColor[0] : fontColor[1]}>
            {props.leftTitle}
          </Text>
          {left ? (
            <Image
              source={Images.iconHorizontalGradient}
              style={{
                height: 2,
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}
            />
          ) : null}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            setleft(false);
          }}
        >
          <Text style={left ? fontColor[1] : fontColor[0]}>
            {props.rightTitle}
          </Text>
          {!left ? (
            <Image
              source={Images.iconHorizontalGradient}
              style={{
                height: 2,
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}
            />
          ) : null}
        </TouchableOpacity>
      </View>
      {left ? props.leftComponent : props.rightComponent}
    </View>
  );
};

export default NotificationTopBarComponent;

const styles = StyleSheet.create({
  topBarContainer: {
    width: "100%",
    height: "7%",
    flexDirection: "row",
  },
});
