export default {
  background: require("../assets/images/background.png"),
  splashBackground: require("../assets/images/ill_background.jpg"),
  logo: require("../assets/images/logo.png"),
  imagePlaceholder: require(`../assets/images/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }image_placeholder_new.png`),
  defaultArtist: require("../assets/images/default_artist.png"),
  defaultFestival: require("../assets/images/default_festival.png"),
  defaultVenue: require("../assets/images/default_venue.png"),
  defaultOther: require("../assets/images/default_other.png"),
  defaultEvent: require("../assets/images/default_event.png"),
  defaultGeneral: require(`../assets/images/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }default_general.png`),
  logoTitle: require("../assets/images/logo_title.png"),
  logoDescription: require("../assets/images/logo_description.png"),
  logoWithTitle: require("../assets/images/logo_with_title.png"),
  iconEmail: require("../assets/icons/ic_email.png"),
  iconFacebook: require("../assets/icons/ic_facebook.png"),
  iconGoogle: require("../assets/icons/ic_google.png"),
  iconApple: require("../assets/icons/ic_apple.png"),
  iconAngleLeft: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_arrow_left.png`),
  iconAngleLeftWhite: require("../assets/icons/ic_arrow_left.png"),
  iconChecked: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_check.png`),
  iconUnChecked: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_uncheck.png`),
  iconEyeOpen: require("../assets/icons/ic_eye_open.png"),
  iconEyeClosed: require("../assets/icons/ic_eye_closed.png"),
  iconError: require("../assets/icons/ic_error.png"),
  iconChat: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_chat.png`),
  iconChatEmpty: require("../assets/icons/ic_chat_empty.png"),
  iconSearch: require("../assets/icons/ic_search.png"),
  iconNotification: require("../assets/icons/ic_notification.png"),
  iconLocationPin: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_location_pin.png`),
  iconPlus: require("../assets/icons/ic_plus.png"),
  iconPlusColored: require("../assets/icons/ic_plus_colored.png"),
  iconPlusGray: require("../assets/icons/ic_plus_gray.png"),
  iconBooking: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_bookings.png`),
  iconShare: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_share.png`),
  iconShareColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_share_colored.png`),
  iconCalendar: require("../assets/icons/ic_calendar.png"),
  iconTicketEmpty: require("../assets/icons/ic_ticket_empty.png"),
  iconTicket: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_ticket.png`),
  iconTicketWhite: require("../assets/icons/ic_ticket_white.png"),
  iconChatColor: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_chat_colored.png`),
  iconBarcode: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_barcode.png`),
  iconUpdate: require("../assets/icons/ic_update.png"),
  iconReport: require("../assets/icons/ic_report.png"),
  iconReportWhite: require("../assets/icons/ic_report_white.png"),
  iconMore: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_more.png`),
  iconMoreWhite: require("../assets/icons/ic_more.png"),
  iconMoreColored: require("../assets/icons/ic_more_colored.png"),
  iconHome: require("../assets/icons/ic_home.png"),
  iconHomeSelected: require("../assets/icons/ic_home_selected.png"),
  iconFeed: require("../assets/icons/ic_feed_menu.png"),
  iconFeedSelected: require("../assets/icons/ic_feed_menu_selected.png"),
  iconAdd: require("../assets/icons/ic_add_menu.png"),
  iconAddSelected: require("../assets/icons/ic_add_menu_selected.png"),
  iconAccount: require("../assets/icons/ic_account_menu.png"),
  iconAvatarDefault: require("../assets/icons/ic_avatar_default.png"),
  iconAccountSelected: require("../assets/icons/ic_account_menu_selected.png"),
  iconInstagram: require("../assets/icons/ic_instagram.png"),
  iconInsight: require("../assets/icons/ic_insight.png"),
  iconFacebookColored: require("../assets/icons/ic_facebook_colored.png"),
  iconTwitter: require("../assets/icons/ic_twitter_colored.png"),
  iconSpotify: require("../assets/icons/ic_spotify_colored.png"),
  iconAngleRight: require("../assets/icons/ic_angle_right.png"),
  iconDateGray: require("../assets/icons/ic_time_gray.png"),
  iconDateColored: require("../assets/icons/ic_time_colored.png"),
  iconGenderColored: require("../assets/icons/ic_gender_colored.png"),
  iconFilterGray: require("../assets/icons/ic_filter_gray.png"),
  iconFilterColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_filter_colored.png`),
  iconListColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_list_colored.png`),
  iconCalendarColored: require("../assets/icons/ic_calendar_colored.png"),
  iconCalendarWhite: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_calendar_white.png`),
  iconSearchVenue: require("../assets/icons/ic_search_venue.png"),
  iconSearchArtist: require("../assets/icons/ic_search_artist.png"),
  iconSearchPromoter: require("../assets/icons/ic_search_promoter.png"),
  iconSearchOther: require("../assets/icons/ic_search_other.png"),
  iconSearchFestival: require("../assets/icons/ic_search_festival.png"),
  iconSearchEvent: require("../assets/icons/ic_search_event.png"),
  iconTagEventType: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_event_type.png`),
  iconTagAmenities: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_amenities.png`),
  iconTagPrice: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_price.png`),
  iconTagPriceGray: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_price_gray.png`),
  iconTagRating1: require("../assets/icons/ic_rating_1.png"),
  iconTagRating2: require("../assets/icons/ic_rating_2.png"),
  iconTagRating3: require("../assets/icons/ic_rating_3.png"),
  iconTagRating4: require("../assets/icons/ic_rating_4.png"),
  iconTagRating5: require("../assets/icons/ic_rating_5.png"),
  iconTagEntry: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_entry.png`),
  iconTagRating: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_rating.png`),
  iconTagLightning: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_lightning.png`),
  iconTagFeature: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_lightning.png`),
  iconTagStar: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_star.png`),
  iconTagService: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_service.png`),
  iconTagAge: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_age.png`),
  iconTagDressCode: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_dress_code.png`),
  iconTagDress: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_dress_code.png`),
  iconTagArea: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_area.png`),
  iconTagTagVenue: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_venue.png`),
  iconTagGeneral: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_general.png`),
  iconTagVenue: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_venue.png`),
  iconTagStatus: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_status.png`),
  iconTagAccomodation: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_accomodation.png`),
  iconTagCountry: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_country.png`),
  iconTagActivity: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_activity.png`),
  iconTagSize: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_size.png`),
  iconTagDuration: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_duration.png`),
  iconTagMusic: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_music.png`),
  iconTagParticipation: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_participation.png`),
  iconTagAccess: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_status.png`),
  iconTagMeal: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_service.png`),
  iconTagView: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_view.png`),
  iconTagFloor: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tag_area.png`),
  iconClose: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_close.png`),
  iconMenuMore: require("../assets/icons/ic_menu_more.png"),
  iconActionShare: require("../assets/icons/ic_action_share.png"),
  iconActionComment: require("../assets/icons/ic_action_comment.png"),
  iconActionFavorite: require("../assets/icons/ic_action_favorite.png"),
  iconSortGray: require("../assets/icons/ic_sort_gray.png"),
  iconSortColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_sort_colored.png`),
  iconRadioChecked: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_radio_checked.png`),
  iconRadioUnchecked: require("../assets/icons/ic_radio_unchecked.png"),
  iconVerifiedBadge: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_verified_badge.svg`),
  iconVerifiedBadgeMedium: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_verified_badge_medium.svg`),
  iconPlayButton: require("../assets/icons/ic_play_button.png"),
  iconFavoriteColored: require("../assets/icons/ic_favorite_colored.png"),
  iconUnfollowColored: require("../assets/icons/ic_unfollow_colored.png"),
  iconBlockColored: require("../assets/icons/ic_block_colored.png"),
  iconReportColored: require("../assets/icons/ic_report_colored.png"),
  iconPlusWhite: require("../assets/icons/ic_plus_white.png"),
  iconSortWhite: require("../assets/icons/ic_sort_white.png"),
  iconSuccess: require("../assets/icons/ic_success.png"),
  exampleEventPoster: require("../assets/images/event_example_poster.png"),
  iconArrowCollapsed: require("../assets/icons/ic_arrow_collapsed.png"),
  iconArrowExpanded: require("../assets/icons/ic_arrow_expanded.png"),
  iconArrowExpandedGray: require("../assets/icons/ic_arrow_expanded_gray.png"),
  iconUserWhite: require("../assets/icons/ic_user_white.png"),
  iconUserGroup: require("../assets/icons/ic_user_group.png"),
  iconEmailColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_email_colored.png`),
  iconPhoneColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_phone_colored.png`),
  iconWebsiteColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_website_colored.png`),
  iconWebsiteGray: require("../assets/icons/ic_website_gray.png"),
  iconInstagramLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_instagram_link_circle.png`),
  iconFacebookLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_facebook_link_circle.png`),
  iconTwitterLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_twitter_link_circle.png`),
  iconWikipediaLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_wikipedia_link_circle.png`),
  iconYoutubeLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_youtube_link_circle.png`),
  iconSpotifyLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_spotify_link_circle.png`),
  iconTiktokLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_tiktok_link_circle.png`),
  iconExpandMap: require("../assets/icons/ic_expand_map.png"),
  iconFilterList: require("../assets/icons/ic_filter_list.png"),
  iconOutlookCalendar: require("../assets/icons/ic_outlook_calendar.png"),
  iconGoogleCalendar: require("../assets/icons/ic_google_calendar.png"),
  iconAttachColored: require("../assets/icons/ic_attach_colored.png"),
  iconAttach: require("../assets/icons/ic_attach.png"),
  iconDelete: require("../assets/icons/ic_delete.png"),
  iconTicketTicketmaster: require("../assets/icons/ic_ticket_ticketmaster.png"),
  iconTicketViagogo: require("../assets/icons/ic_ticket_viagogo.png"),
  iconTicketSeeTickets: require("../assets/icons/ic_ticket_see_tickets.png"),
  iconAngleRightColored: require("../assets/icons/ic_angle_right_colored.png"),
  iconHelp: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_help.png`),
  iconUser: require("../assets/icons/ic_user.png"),
  iconSettings: require("../assets/icons/ic_settings.png"),
  iconSupport: require("../assets/icons/ic_support.png"),
  iconRating: require("../assets/icons/ic_rating.png"),
  iconTermsPolicy: require("../assets/icons/ic_terms_policy.png"),
  iconLogout: require("../assets/icons/ic_logout.png"),
  iconEdit: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_edit.png`),
  iconMenu: require("../assets/icons/ic_menu.png"),
  iconMenuMagic: require("../assets/icons/ic_menu_magic.png"),
  imagePickerExample: require("../assets/images/image_picker_example.png"),
  iconDropDown: require("../assets/icons/ic_dropdown.png"),
  iconCamera: require("../assets/icons/ic_camera.png"),
  dummyCameraView: require("../assets/images/dummy_camera.png"),
  photoButton: require("../assets/images/photo_button.png"),
  iconGallery: require("../assets/icons/ic_gallery.png"),
  iconUnFlash: require("../assets/icons/ic_flash.png"),
  iconColoredCamera: require("../assets/icons/ic_colored_camera.png"),
  iconHeart: require("../assets/icons/ic_heart.png"),
  iconMsg: require("../assets/icons/ic_msg.png"),
  iconWhatsapp: require("../assets/icons/ic_whatsapp.png"),
  iconInstagramBlack: require("../assets/icons/ic_instagram_black.png"),
  iconPinterest: require("../assets/icons/ic_pinterest.png"),
  iconPhoto: require("../assets/icons/ic_photo.png"),
  iconBlackSpotify: require("../assets/icons/ic_black_spotify.png"),
  iconContact: require("../assets/icons/ic_contact.png"),
  iconArchieve: require("../assets/icons/ic_archieve.png"),
  iconAddNewMsg: require("../assets/icons/ic_add_new_msg.png"),
  iconHorizontalGradient: require("../assets/icons/ic_horizontal_gradient.png"),
  iconNotificationColored: require("../assets/icons/ic_notification_colored.png"),
  iconUserRequest: require("../assets/icons/ic_user_request.png"),
  iconShareCircleBorder: require("../assets/icons/ic_share_circle_border.png"),
  iconCommentCircleBorder: require("../assets/icons/ic_comment_circle_border.png"),
  iconLikeCircleBorder: require("../assets/icons/ic_like_circle_border.png"),
  iconFollowCircleBorder: require("../assets/icons/ic_follow_circle_border.png"),
  iconCameraFlashEnable: require("../assets/icons/ic_camera_flash_enable.png"),
  iconCameraPhoto: require("../assets/icons/ic_camera_photo.png"),
  iconCameraVideo: require("../assets/icons/ic_camera_video.png"),
  iconSend: require("../assets/icons/ic_send_text.png"),
  iconFavoriteWhite: require("../assets/icons/ic_favorite_white.png"),
  iconCommentWhite: require("../assets/icons/ic_comment_white.png"),
  iconDefaultTicket: require("../assets/icons/ic_default_ticket.png"),
  iconRepostColored: require("../assets/icons/ic_repost.png"),
  iconLinkColored: require("../assets/icons/ic_link.png"),
  iconMessagerCircle: require("../assets/icons/ic_messager_circle.png"),
  iconFacebookCircle: require("../assets/icons/ic_facebook_circle.png"),
  iconWhatsappCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_whatsapp_circle.png`),
  iconInstagramCircle: require("../assets/icons/ic_instagram_circle.png"),
  iconGmailCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_gmail_circle.png`),
  iconPinterestCircle: require("../assets/icons/ic_pinterest_circle.png"),
  iconTelegramCircle: require("../assets/icons/ic_telegram_circle.png"),
  iconLinkedinCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_linkedin_circle.png`),
  iconLinkCircle: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_link_circle.png`),
  iconFestivalLiveStatus: require("../assets/icons/ic_live.png"),
  iconEventEmpty: require("../assets/icons/ic_festival_big.png"),
  iconVideoPlay: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_video_play.png`),
  iconWorldwide: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_worldwide.png`),
  iconWorldwideColored: require(`../assets/icons/${
    process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
    process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
    process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
    process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
    process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
    process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
      ? process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")?.toLowerCase() + "/"
      : ""
  }ic_worldwide_colored.png`),
};
