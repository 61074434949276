export default {
  facebook: {
    appId: '980986049233918',
  },
  google: {
    clientId:
      '425749730400-adjpclg4oivhbaetn12n5q204sq0i30l.apps.googleusercontent.com',
  },
  apple: {
    appId: 'com.soundclub.service',
  },
  spotify: {
    clientId: '75384405735a4f608e836b2ebe6b947d',
  },
  urlScheme: 'soundclubapp',
}
