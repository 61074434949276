import moment from "moment";

export function calculateMilisecondDiff(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const diffMiliseconds = end.diff(start);
  if (diffMiliseconds < 0) return 0;
  return diffMiliseconds;
}
export function getUTCTime(date, timezoneValue) {
  if (!timezoneValue) return date;
  if (!date) return null;

  const symbol = timezoneValue[0];
  const operator = symbol == "-" ? 1 : -1;
  const hours = operator * Number(timezoneValue.substring(1, 3));
  const minutes = operator * timezoneValue.substring(4);
  const utcDate = moment(date)
    .add(hours, "hours")
    .add(minutes, "minutes")
    .utc()
    .toISOString();

  return utcDate;
}

export function getBeginOfDay(date) {
  const dateString = moment(date).format("YYYY-MM-DD");
  return dateString + " 00:00:00";
}
export function getEndOfDay(date) {
  const dateString = moment(date).format("YYYY-MM-DD");
  return dateString + " 23:59:59";
}

export function getPeriodString(miliseconds) {
  const delta = miliseconds;
  if (delta > 86400000 * 30) {
    const days = Math.ceil(delta / (86400000 * 30));
    return days + " months";
  }
  if (delta >= 86400000) {
    const days = Math.ceil(delta / 86400000);
    return days + " days";
  }
  if (delta >= 3600000) {
    const days = Math.ceil(delta / 3600000);
    return days + " mins";
  }
  if (delta >= 60000) {
    const days = Math.ceil(delta / 60000);
    return days + " seconds";
  }
  return "";
}
export function getPeriod(miliseconds) {
  let delta = Math.floor(miliseconds / 1000);

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = delta % 60;
  return {
    days,
    hours,
    minutes,
    seconds,
  };
}
