export default {
  primaryColor: "#161616",
  green: "#34aa47",
  orange: "#ff5722",
  lightGreen: "#00ea81",
  gray: "#ffffff20",
  black: "#161616",
  black2: "#222",
  black3: "#A2A2A2",
  black4: "rgba(40, 40, 40, 0.8)",
  black5: "#101010",
  spliterLine: "#414141",
  white: "#FFFFFF",
  placeholder: "#939393",
  whiteText: "#FFFFFFCC",
  whiteHalf: "#FFFFFF80",
  grayBackground: "#282828",
  grayBackground2: "#1B1818",
  grayBackground3: "rgba(40, 40, 40, 0.8)",
  grayText: "#535353",
  grayText2: "#939393",
  darkGray: "#A4A4AF",
  lightGray: "#DBDBDB",
  tabBottomColor: "#343434",
  barTansparentBg: "#FFFFFF28",
  white5: "#FFFFFF0C",
  white60: "#FFFFFF78",
  white20: "#FFFFFF28",
  white10: "#FFFFFF19",
  white30: "#FFFFFF4C",
  white70: "#FFFFFFB2",
  white80: "#FFFFFFCC",
  white50: "#FFFFFF80",
  white40: "#FFFFFF50",
  grayBorder: "#676767",
  grey: "#676767",
  grey2: "#757373",
  gradient1: "#F526D6",
  gradient2: "#F526D6",
  gradient3: "#44B3F8",
  pink: "#F526D6",
  blue: "#44B3F8",
  purple: "#A408FE",
  topBarColor: "#252525",
  darkGrayCard: "#282828",
  red: "red",
  zooGreen: "#00D870",
  zooBlue: "#00A3FF",
  rooftopBlue: "#00A3FF",
  rooftopPink: "#EA06FE",
  prefabGray: "#757373",
  prefabBlack: "#0A0A0A",
  parkfulBlue: "#0075FF",
  parkfulPink: "#EA06FE",
  casinoBlack: "#0A0A0A",
  casinoRed: "#EA0000",
  castleGray: "#494949",
  castleBlack: "#0A0A0A",
  resortBlue: "#00A3FF",
  resortPink: "#FC6AFF",
  aparthotelBlack: "#0A0A0A",
  aparthotelPink: "#EA06FE",
  rooftopBlue: "#00A3FF",
  rooftopPink: "#EA06FE",
  cocktaylBlack: "#0A0A0A",
  cocktaylPink: "#EA06FE",
  spaGray: "#757373",
  spaBlue: "#00A3FF",
  beachPink: "#FC6AFF",
  beachGray: "#757373",
};
