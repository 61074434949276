import React from "react";
// import MultiSlider from '@ptomasroos/react-native-multi-slider'
import { Colors } from "../../config";

const CSlider = (props) => {
  return (
    // <MultiSlider
    //   trackStyle={{
    //     height: 4,
    //     borderRadius: 4,
    //     backgroundColor: Colors.white10,
    //   }}
    //   trackGradientColors={['#F526D6', '#A408FE', '#44B3F8']}
    //   markerStyle={{
    //     backgroundColor: Colors.white,
    //     height: 16,
    //     width: 16,
    //     borderRadius: 100,
    //   }}
    //   {...props}
    // />
    null
  );
};

export default CSlider;
