// @flow
import { Platform } from "react-native-web";

var ios = {
  primaryThin: "Montserrat-Thin",
  primaryLight: "Montserrat-Light",
  primaryRegular: "Montserrat-Regular",
  primaryRegularItalic: "Montserrat-Italic",
  primaryMedium: "Montserrat-Medium",
  primaryMediumItalic: "Montserrat-MediumItalic",
  primarySemiBold: "Montserrat-SemiBold",
  primaryBold: "Montserrat-Bold",
  primaryBoldItalic: "Montserrat-BoldItalic",
};

var android = {
  primaryThin: "Montserrat-Thin",
  primaryLight: "Montserrat-Light",
  primaryRegular: "Montserrat-Regular",
  primaryRegularItalic: "Montserrat-Italic",
  primaryMedium: "Montserrat-Medium",
  primaryMediumItalic: "Montserrat-MediumItalic",
  primarySemiBold: "Montserrat-SemiBold",
  primaryBold: "Montserrat-Bold",
  primaryBoldItalic: "Montserrat-BoldItalic",
};

export default Platform.OS == "ios" ? ios : android;
