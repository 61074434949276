import uniq from "lodash/uniq";
export function toggleItemInItems(items, newItem, idFieldName = "id") {
  if (!items) return [];
  const alreadyExist = items.find(
    (x) => x[idFieldName] == newItem[idFieldName]
  );
  if (!alreadyExist) {
    return [...items, newItem];
  } else {
    return items.filter((x) => x[idFieldName] != newItem[idFieldName]);
  }
}

export function makeArrayUniq(arr) {
  if (arr && Array.isArray(arr)) {
    return uniq(arr);
  }
  return [];
}

export function objectToFormData(obj, rootName, ignoreList) {
  var formData = new FormData();

  function appendFormData(data, root) {
    if (!ignore(root)) {
      root = root || "";
      if (
        data instanceof File ||
        [
          "image",
          "avatar",
          "coverPic",
          "photo",
          "thumbnailImage",
          "photos_memory_post",
          "video_memory_post",
          "photos",
          "video",
        ].some((el) => root.includes(el))
      ) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          appendFormData(data[i], root + "[" + i + "]");
        }
      } else if (typeof data === "object" && data) {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === "") {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + "[" + key + "]");
            }
          }
        }
      } else {
        if (data !== null && typeof data !== "undefined") {
          formData.append(root, data);
        }
      }
    }
  }

  function ignore(root) {
    return (
      Array.isArray(ignoreList) &&
      ignoreList.some(function (x) {
        return x === root;
      })
    );
  }

  appendFormData(obj, rootName);

  return formData;
}
