import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native-web";
import React from "react";
import { Colors, Fonts, Images } from "../../../config";

const ChatTopBarComponent = (props) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          null;
        }}>
        <Image source={Images.iconAngleLeftWhite} style={styles.icon} />
      </TouchableOpacity>
      <View
        style={{
          marginLeft: 24,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Image source={Images.iconPhoto} />
        <View style={{ marginLeft: 14 }}>
          <Text style={styles.name}>{props.name}</Text>
          <Text style={styles.status}>Last seen recently</Text>
        </View>
      </View>

      <View
        style={{
          position: "absolute",
          right: 10,
          flexDirection: "row",
          alignItems: "center",
        }}>
        <TouchableOpacity
          onPress={() => {
            null;
          }}>
          <Image
            source={Images.iconMore}
            style={{ width: 22, height: 22, resizeMode: "contain" }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ChatTopBarComponent;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Colors.black,
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  name: {
    fontSize: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    lineHeight: 18,
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: "contain",
  },
  status: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white30,
    lineHeight: 18,
  },
});
