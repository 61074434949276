import React from "react";
import { StyleSheet, Text } from "react-native-web";

const LinearGradientView = (props) => {
  return (
    <div
      className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
      style={props.style}
    >
      {props.children}
    </div>
  );
};

const styles = StyleSheet.create({});

export default LinearGradientView;
